import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <p>Thank you for using Astrolab's apps.</p>

      <p>This service is provided by Joanna Liu at no cost and is intended for use as is.</p>

      <p>This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service.</p>

      <p>If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for providing and improving the Service. </p>

      <p>I will not use, share, or sell your information with anyone except as described in this Privacy Policy.</p>

      <p>The terms used in this Privacy Policy have the same meanings as in our Terms of Service, which is accessible at Exquisite Corpse Online unless otherwise defined in this Privacy Policy.</p>

      {/* <h2>Information Collection and Use</h2> */}
      <h2>Information Collection and Use</h2>
      <p>For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information. The information that I request will be retained on your device and is not collected by me in any way.</p>

      <p>
      The public will only be able to see the lines you compose and share. The public will not see any of your personal information. You may share your information in ways you find convenient and as you choose. You agree to take all necessary precautions in all interactions with other users. I shall have no liability to you whatsoever in relation to your interactions with other users of the App. I also provide users the means to block and report other users.
      </p>

      <p>As noted above, I do not sell or rent your Personal Data to third parties. I do not investigate the backgrounds of any users or check the information provided by its users. </p>

      <p>The app does use third party services that may collect information used to identify you.
        Link to privacy policy of third party service providers used by the app <br/>
        -<a href="https://support.google.com/admob/answer/6128543?hl=en">AdMob</a> <br/>
        -<a href="https://firebase.google.com/policies/analytics">Google Analytics for Firebase</a> <br/>
      </p>

      {/* <h2>Data</h2> */}
      <h2>Data</h2>
      <p>I want to inform you that whenever you use my Service, in a case of an error in the app, I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, browser type and version, browser plug-in types and versions, time zone setting and location, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics. Usage Data may include information about how you use our website, products and services.</p>

      <p>I collect personal data, such as your username (including the real name of the user if applicable) and email and password and retain your information only for as long as is necessary to achieve the purposes of this privacy policy. </p>

      <p>When the data is no longer needed or kept to comply with legal or regulatory duties, I’ll delete or depersonalize it so you can not be identified.</p>

      {/* <h2>Cookies</h2> */}
      <h2>Cookies</h2>
      <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>

      <p>This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>

      {/* <h2>Service Providers</h2> */}
      <h2>Service Providers</h2>
      <p>I may employ third-party companies and individuals due to the following reasons:</p>

      <p>- To facilitate our Service<br />
      - To provide the Service on our behalf<br />
      - To perform Service-related services<br />
      - To assist us in analyzing how our Service is used</p>

      <p>I want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

      {/* <h2>Security</h2> */}
      <h2>Security</h2>
      <p>I value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.</p>

      <p>If you wish to access or delete your information that I collect and retain please contact us at privacy<span className="spamprotection" style={{ display: 'none'}}>CHARACTER SEQUENCE</span>@astrolab.app and provide your pen name and email. After I verify your identity, I usually can enact within 30 days.
      </p>

      <p>While I can not guarantee your account from hacking by an unauthorized party, let me know right away of any unauthorized use or compromise to your account.</p>

      {/* <h2>Links to Other Sites</h2> */}
      <h2>Links to Other Sites</h2>
      <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>


      <h2>Children's Privacy</h2>
      <p>These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary actions.</p>


      <h2>Residents of the European Economic Area</h2>
      <p>If you a resident of the European Economic Area (“EEA”) and in accordance with the General Data Protection Regulation (“GDPR”), you have the rights listed under this privacy policy:</p>

      <p>Request access to your Personal Data. You have the right to access, update or delete your information that we collect and retain. You can access certain of your Personal Data within your Astrolab account settings. If you have any questions or would like to a copy of your Personal Data, please contact us.</p>

      <p>Request correction of Personal Data. You have the right to have any incomplete or inaccurate information we hold corrected.</p>

      <p>Object to processing of your Personal Data. This right exists where you object to our processing of your Personal Data. You also have the right to object should Astrolab process your Personal Data for direct marketing purposes.</p>

      <p>Request erasure of your Personal Data. You have the right to ask us to delete or remove your Personal Data when no legitimate reason exists for use to continue processing it.</p>

      <p>Request the transfer of your Personal Data. We will provide you, or to a third-party you designate, your Personal Data in a structured, commonly used, and readable format. This right only applies to automated information that you initially provided consent for us to use or where we used the information to perform a contract with you.</p>

      <p>Withdraw your consent. You have the right to withdraw your consent on using your Personal Data. If you withdraw your consent, we may not be able to provide you continued service and may need to disable your account.</p>

      <h2>Exercising of Your GDPR Data Protection Rights</h2>
      <p>If you have any questions or wish to exercise your rights of access, rectification, cancellation and opposition please contact us at privacy<span className="spamprotection" style={{ display: 'none'}}>CHARACTER SEQUENCE</span>@astrolab.app and provide your full name and username. After we verify your identity, we usually can provide your information within 30 days. You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the EEA. </p>


      <h2>California Residents</h2>
      <p>The California Consumer Privacy Act (“CCPA”) requires us to disclose categories of Personal Information we collect and how we use it, the categories of sources from whom we collect Personal Information, and the third parties with whom we share it, which we have explained above.</p>

      <p>In the preceding twelve months, we have collected Personal Information from you, your devices, our partners, third parties, and from categories of sources described in this privacy policy. We are also required to communicate information about rights California residents have under California law. You may exercise the following rights:</p>

      <p>Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us; (3) categories of sources from which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you.</p>

      <p>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.</p>

      <p>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal Information about you that we have collected.</p>

      <p>Note: California Business and Professions Code Section 22581 also permits California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.</p>

      <p>We do not sell the Personal Information of our users.</p>

      <p>For more information about these rights, please see “Your options on our use of information” above, and to exercise them, please contact us at privacy<span className="spamprotection" style={{ display: 'none'}}>CHARACTER SEQUENCE</span>@astrolab.app and provide your full name and email. After we verify your identity, we usually can provide your information within 30 days.</p>

      <p>We use and disclose this information for the business purposes described in this policy, including to: audit our Service, detect security incidents and prevent fraud, identify and fix bugs, maintain your account, provide customer service, conduct research and development and other activities to improve our Service, market our Services, and understand how users interact with our Services.</p>

      <h2>Changes to this Privacy Policy</h2>
      <p>I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at privacy<span className="spamprotection" style={{ display: 'none'}}>CHARACTER SEQUENCE</span>@astrolab.app.</p>
      <p>This privacy policy page was created at privacypolicytemplate.net and modified/generated by App Privacy Policy Generator.</p>
      <p>Effective date: 1/1/2021</p>

    </div>
  </Layout>
)

export default PrivacyPolicy
